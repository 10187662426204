.sm-page-widget-feed .sm-feed {
  max-width: 100%;
}
.sm-page-widget-feed .sm-feed-item {
  margin: 0 0 72px;
  overflow: hidden;
}
.sm-page-widget-feed .sm-feed-item .sm-feed-item-title {
  display: inline-block;
}
.sm-page-widget-feed .sm-feed-item .sm-feed-item-description {
  margin-top: 8px;
}
.sm-page-widget-feed .sm-feed-item img {
  max-width: 100%;
}
@media screen and (max-width: 736px) {
  .sm-page-widget-feed .sm-feed-item .sm-feed-item-date {
    -webkit-text-size-adjust: none;
  }
  .sm-page-widget-feed .sm-feed-item .sm-feed-item-description {
    font-size: 16px;
    -webkit-text-size-adjust: none;
  }
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9jb2RlYnVpbGQvb3V0cHV0L3NyYzc3Ni9zcmMvYmYwZGQ1OTBfNGUwOV80NjJhXzhhMTJfZTY1MjEyZmQ4NWVmL2FjdGlvbnMtcnVubmVyL193b3JrL1NtdWdNdWcvU211Z011Zy9kb2Nyb290L2luY2x1ZGUvY3NzL3NtdWdtdWcvd2lkZ2V0cy9mZWVkLmxlc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRUEsb0JBQXFCO0VBQ2pCLGVBQUE7O0FBR0osb0JBQXFCO0VBQ2pCLGdCQUFBO0VBQ0EsZ0JBQUE7O0FBR0osb0JBQXFCLGNBQWM7RUFDL0IscUJBQUE7O0FBR0osb0JBQXFCLGNBQWM7RUFDL0IsZUFBQTs7QUFHSixvQkFBcUIsY0FBYztFQUMvQixlQUFBOztBQUdKLG1CQUFnRDtFQUM1QyxvQkFBcUIsY0FBYztJQUMvQiw4QkFBQTs7RUFHSixvQkFBcUIsY0FBYztJQUMvQixlQUFBO0lBQ0EsOEJBQUEiLCJzb3VyY2VzQ29udGVudCI6WyJAaW1wb3J0IFwiLi4vdmFyaWFibGVzXCI7XG5cbi5zbS1wYWdlLXdpZGdldC1mZWVkIC5zbS1mZWVkIHtcbiAgICBtYXgtd2lkdGg6IDEwMCU7XG59XG5cbi5zbS1wYWdlLXdpZGdldC1mZWVkIC5zbS1mZWVkLWl0ZW0ge1xuICAgIG1hcmdpbjogMCAwIDcycHg7XG4gICAgb3ZlcmZsb3c6IGhpZGRlbjtcbn1cblxuLnNtLXBhZ2Utd2lkZ2V0LWZlZWQgLnNtLWZlZWQtaXRlbSAuc20tZmVlZC1pdGVtLXRpdGxlIHtcbiAgICBkaXNwbGF5OiBpbmxpbmUtYmxvY2s7XG59XG5cbi5zbS1wYWdlLXdpZGdldC1mZWVkIC5zbS1mZWVkLWl0ZW0gLnNtLWZlZWQtaXRlbS1kZXNjcmlwdGlvbiB7XG4gICAgbWFyZ2luLXRvcDogOHB4O1xufVxuXG4uc20tcGFnZS13aWRnZXQtZmVlZCAuc20tZmVlZC1pdGVtIGltZyB7XG4gICAgbWF4LXdpZHRoOiAxMDAlO1xufVxuXG5AbWVkaWEgc2NyZWVuIGFuZCAobWF4LXdpZHRoOiBAc21hbGwtbWF4LXdpZHRoKSB7XG4gICAgLnNtLXBhZ2Utd2lkZ2V0LWZlZWQgLnNtLWZlZWQtaXRlbSAuc20tZmVlZC1pdGVtLWRhdGUge1xuICAgICAgICAtd2Via2l0LXRleHQtc2l6ZS1hZGp1c3Q6IG5vbmU7XG4gICAgfVxuXG4gICAgLnNtLXBhZ2Utd2lkZ2V0LWZlZWQgLnNtLWZlZWQtaXRlbSAuc20tZmVlZC1pdGVtLWRlc2NyaXB0aW9uIHtcbiAgICAgICAgZm9udC1zaXplOiAxNnB4O1xuICAgICAgICAtd2Via2l0LXRleHQtc2l6ZS1hZGp1c3Q6IG5vbmU7XG4gICAgfVxufVxuIl19 */